import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <link rel="canonical" href={seo.url} />}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "RoofingContractor",
                    "currenciesAccepted": "GBP",
                    "paymentAccepted": "Cash, Credit Card, Bank Transfer",
                    "address": {
                      "@type": "PostalAddress",
                      "addressLocality": "Wishaw",
                      "addressRegion": "North Lanarkshire",
                      "postalCode": "ML2 0EE",
                      "streetAddress": "111 Jennie Lee Drive",
                      "addressCountry": "Scotland"
                    },
                    "areaServed": "Scotland",
                    "email": "stevenson-roofing@outlook.com",
                    "founder": "Tom Stevenson",
                    "legalName": "Stevenson Roofing",
                    "name": "Stevenson Roofing",
                    "telephone": "07760 247029‬, 01698 372048",
                    "logo": "https://roofinglanarkshire.co.uk/img/logo.jpg",
                    "image": "https://roofinglanarkshire.co.uk/img/logo.jpg",
                    "alternateName": "Roofing Lanarkshire",
                    "description": "Stevenson Roofing is a family run roofing business based in Wishaw, Scotland with over 30 years of experience. Services include: Full roof installs, Lift and replace, Soffits and Facias, Firestone flat roofing, Guttering and down-piping, Single ply flat roofing, Lead work and more.",
                    "url": "https://roofinglanarkshire.co.uk",
                    "priceRange": "$-$$$"
                }
            `}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`
