import React from "react"

const Footer = () => (
  <footer>
    <p>Copyright © 2020 STEVENSON ROOFING</p>
    Designed by Chris Hunter
  </footer>
)

export default Footer
