import React, { Component } from "react"
import { routes } from "../routes/routes"
import { Link } from "gatsby"

class NavBar extends Component {
  toggleBurger() {
    document.getElementById("navBurger").classList.toggle("is-active")
    document.getElementById("navMenu").classList.toggle("is-active")
  }

  render() {
    return (
      <div>
        <nav className="navbar is-link">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <i className="fas fa-chevron-up" />{" "}
              <span className="fa-margin">STEVENSON ROOFING</span>
            </a>

            <div
              role="navigation"
              aria-label="Main"
              id="navBurger"
              onClick={this.toggleBurger.bind(this)}
              className="navbar-burger"
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            role="navigation"
            aria-label="Main"
            id="navMenu"
            onClick={this.toggleBurger.bind(this)}
            className="navbar-menu"
          >
            <div className="navbar-end">
              <Link to={routes.home} className="navbar-item">
                Home
              </Link>
              <Link to={routes.about} className="navbar-item">
                About
              </Link>
              <Link to={routes.services} className="navbar-item">
                Services
              </Link>
              <Link to={routes.projects} className="navbar-item">
                Projects
              </Link>
              <Link to={routes.contact} className="navbar-item">
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default NavBar
