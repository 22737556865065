import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <div className="parent" id="header">
        <div className="child" id="header-text">
          <h1 className="">STEVENSON ROOFING</h1>
          <h2 className="">ROOFING SERVICES IN SCOTLAND</h2>
        </div>
      </div>
    );
  }
}

export default Header;

// <img src={imgRoutes.heroMain} alt="Main roof header" />
