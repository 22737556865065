export const imgRoutes = {
  heroMain: "img/downloads/used/heroroof.jpg"
};

export const projectRoutes = {
  facia1: {
    url: "img/projects/facia1.jpg",
    alt: "Roofing facia, white around corner of building"
  },
  roof1: {
    url: "img/projects/roof1.jpg",
    alt: "House with red roof and bay window roofing"
  },
  roof11: {
    url: "img/projects/roof11.jpg",
    alt: "Charcoal roof with black gutters"
  },
  roof2: {
    url: "img/projects/roof6.jpg",
    alt: "House with brown roof and white gutters. Velux window in roof"
  },
  roof3: {
    url: "img/projects/roof3.jpg",
    alt: "Charcoal roof on white house"
  },
  roof4: {
    url: "img/projects/roof4.jpg",
    alt: "Charcoal roof, side on view"
  },
  roof5: {
    url: "img/projects/roof5.jpg",
    alt: "Side on view of house with brown roof, tiled roofing"
  },
  roof6: {
    url: "img/projects/roof6.jpg",
    alt: "Brown roof with Velux window"
  },
  roof7: {
    url: "img/projects/roof7.jpg",
    alt: "Brown roof with Velux window"
  },
  roof8: {
    url: "img/projects/roof8.jpg",
    alt: "Charcoal multi sloped roof, Slated roofing"
  },
  roof9: {
    url: "img/projects/roof9.jpg",
    alt: "Double roof, left is terracotta, right is charcoal tiles"
  },
  roof10: {
    url: "img/projects/roof10.jpg",
    alt: "Terracotta roof with Velux window"
  }
};

export const featureImg = {
  longroof1: {
    url: "img/projects/longroof1.jpg",
    alt: "Wide view of slate roof, with black gutters and downpipes"
  }
};

export const toolRoutes = {
  sawing1: {
    url: "img/tools/sawing1.jpg",
    alt: "Workman sawing wood with circular saw on roof"
  },
  tools1: {
    url: "img/tools/tools1.jpg",
    alt: "Toolbelt with hammer and spanner on a roof"
  }
};

export const layoutImg = {
  layout1: {
    url: "img/layout-images/left-feature-image.jpg",
    alt:
      "Feature image of man working with pencil and wood. Right hand side is tiled roof"
  }
};
