import React from "react"
import NavBar from "../components/NavBar"
import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import "../styles/app.sass"

export default ({ children, seoProps }) => (
  <>
    <SEO {...seoProps} />
    <NavBar />
    <Header />
    {children}
    <Footer />
  </>
)
